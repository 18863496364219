.app-loader-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #00000099;
  position: fixed;
  z-index: 10001;
  padding: 20px;
  overflow: auto;

  .spinner-container {
    width: 150px;
    height: 150px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    .circle {
      width: 100%;
      position: relative;
      -webkit-animation: circle-spin 2s infinite linear;
      animation: circle-spin 2s infinite linear;
    }
    .logo {
      position: absolute;
      width: 70%;
      -webkit-animation: logo-zooming 2s infinite linear;
      animation: logo-zooming 2s infinite linear;
    }
  }
  .text {
    margin-top: 20px;
    font-size: 1rem;
    color: #fff;
  }
}

@-webkit-keyframes circle-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes circle-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes logo-zooming {
  0% {
    width: 70%;
  }
  50% {
    width: 80%;
  }
  100% {
    width: 70%;
  }
}
@keyframes logo-zooming {
  0% {
    width: 70%;
  }
  50% {
    width: 80%;
  }
  100% {
    width: 70%;
  }
}
