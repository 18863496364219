@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap");

body {
  margin: 0;
  font-size: 0.9rem;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Inter",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.index-form {
  background-color: #38030c;
}

.overlay {
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000000a6;
  position: fixed;
  z-index: 1000;
  padding: 100px 20px;
  overflow: auto;
}

.no-padding {
  padding: 0 !important;
}

/* .no-scroll {
  overflow: hidden !important;
} */

.w-available {
  width: auto;
  width: -moz-available;
  width: -webkit-fill-available;
  width: stretch;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: #d4dcdf;
  border-radius: 20px;
  border: 2px solid #d9d9d9;
}
